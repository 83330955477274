const ConfigurationFunction = (): any => {
  const configuration: any = {
    development: {
      appUrl: "http://localhost:8080",
      webAppUrl: "https://web.uat.trainerday.com",
      // apiUrl: "https://coachjack-api.uat.trainerday.com/api/v1",
      apiUrl: "http://localhost:3000/api/v1",
      mainApiUrl: "http://localhost:8090",
      // apiUrl: "https://coachjack.api.trainerday.com/api/v1",
      // coachSvcUrl: "https://coach.api.trainerday.com",
      coachSvcUrl: "http://localhost:8000",
      telegramBotUrl: "https://t.me/coach_jack_notifications_bot",
      telegramBotName: "coach_jack_notifications_bot",
      sentryDsn: "",
      eventsTrackerUrl: "http://localhost:3000/webhook"
    },
    stage: {
      appUrl: "https://app.uat.trainerday.com",
      webAppUrl: "https://web.uat.trainerday.com",
      apiUrl: "https://coachjack-api.uat.trainerday.com/api/v1",
      mainApiUrl: "https://api.uat.trainerday.com",
      coachSvcUrl: "https://strava-dropbox-google.uat.trainerday.com",
      telegramBotUrl: "https://t.me/coach_jack_stage_bot",
      telegramBotName: "coach_jack_stage_bot",
      sentryDsn: "",
      eventsTrackerUrl: "https://stage.events-tracker.trainerday.com/webhook"
    },
    production: {
      appUrl: "https://app.trainerday.com",
      webAppUrl: "https://trainerday.com",
      mainApiUrl: "https://app.api.trainerday.com",
      apiUrl: "https://coachjack.api.trainerday.com/api/v1",
      coachSvcUrl: "https://coach.api.trainerday.com",
      telegramBotUrl: "https://t.me/coach_jack_bot",
      telegramBotName: "coach_jack_bot",
      sentryDsn: "https://b628f40cb1b04596812141ef6ddf1d55@o230172.ingest.sentry.io/5997405",
      eventsTrackerUrl: "https://events-tracker.trainerday.com/webhook"
    }
  }
  let env = process.env.APP_ENV
  if (env === undefined) env = "development"
  return configuration[env]
}

export default ConfigurationFunction()
