<template>
  <div>
    <a v-if="!connectedUsername" class="button connect-telegram" :href="telegramLink" target="_blank">
      <img src="@/assets/images/telegram-logo.svg" alt="Telegram" />
      <span>Link Telegram account</span>
    </a>
    <a v-if="connectedUsername" class="button connect-telegram" :href="telegramLinkConnected" target="_blank">
      <img src="@/assets/images/telegram-logo.svg" alt="Telegram" />
      <span>Connected: @{{ connectedUsername }}</span>
    </a>
  </div>
</template>

<script>
import configuration from "@/configuration"
import { mapState } from "vuex"

const { telegramBotUrl, telegramBotName } = configuration
export default {
  name: "TelegramConnect",
  data() {
    return {
      showInfo: false
    }
  },
  computed: {
    ...mapState("preferences", ["preferences"]),
    telegramLink() {
      const { owner } = this.preferences

      return `${telegramBotUrl}?start=${owner}`
    },
    telegramLinkConnected() {
      return `tg://resolve?domain=${telegramBotName}`
    },
    connectedUsername() {
      if (this.preferences?.telegramInfo?.username && this.preferences?.telegramChatId) {
        return this.preferences?.telegramInfo?.username
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.connect-telegram {
  border: 2px solid #2ab6f5;
  background: #2ab6f5;
  color: white;

  span {
    padding: 0 12px;
  }

  svg {
    max-height: 100%;
  }
}
</style>
