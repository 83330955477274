import axios from "axios"
import * as Sentry from "@sentry/vue"

const responseInterceptor: any = () =>
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function(error) {
      if (process.env.APP_ENV === "production") {
        if (error && error.response?.status > 404) {
          Sentry.captureException({
            error,
            data: JSON.stringify({ url: error.config?.url, message: error.message, status: error.response?.status })
          })
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )
export default responseInterceptor
