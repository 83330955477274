export const FOUR_WEEKS = 4
export const EIGHT_WEEKS = 8
export const TWELVE_WEEKS = 12
export const SIXTEEN_WEEKS = 16
export const SIX_MONTHS_WEEKS = 26
export const ONE_YEAR_WEEKS = 52
export const MIN_WEEKS_DURATION = FOUR_WEEKS
export const MAX_WEEKS_DURATION = 33
export const MAX_EVENTS_WEEKS_DURATION = 49
export const WEEKS_DURATION_CHOOSE_MULTI_BLOCKS = SIXTEEN_WEEKS
export const WEEKLY_GROWTH_RATE = 0.06 // 6%
export const BLOCK_START_HOURS = [1, 2, 3, 4, 5, 7, 8, 10, 12, 15]
export const PEAK_BLOCK_START_HOURS = [1, 2, 3, 4, 5, 7, 8, 10, 12, 15, 20]
export const EVENT_BLOCK_START_HOURS = [1, 2, 3, 4, 5, 7, 8, 10, 12, 15, 20, 25]
export const BLOCK_END_HOURS = [-1, 1, 2, 3, 4, 5, 7, 8, 10, 12, 15, 20, 25] //-1 means Automatic hours
export const MAX_BASE_BLOCK_END_HOURS = 15
export const MAX_BUILD_BLOCK_END_HOURS = 20
export const USER_DEFAULT_FTP = 100
export const DEFAULT_DAILY_MINUTES = -1 // -1 means Unlimited
export const ADD_TEXT1_TO_MESSAGE_FOR_STUDENT = "Coach Robert ask you:"
export const ADD_TEXT2_TO_MESSAGE_FOR_STUDENT = "you may click /ask_the_coach to answer Coach Robert"
export const ROUTE_NAMES = {
  WELCOME: "welcome",
  DCS: "dcs",
  SIGNUP: "signup",
  DASHBOARD: "dashboard",
  WELCOME_DCS: "welcome-dcs",
  WHATS_YOUR_NAME_DCS: "whats-your-name-dcs",
  WHATS_YOUR_NAME: "whats-your-name",
  WHATS_YOUR_FTP: "whats-your-ftp",
  QUESTIONING: "questioning",
  QUESTIONING_DCS: "questioning-dcs",
  SUMMARY: "summary",
  PLAN: "plan",
  PLAN_DCS: "plan-dcs"
}
export const PLAN_CREATOR_MODE = {
  CREATE: "create",
  EDIT: "edit"
}
export const MODELS_TRANSLATION = {
  PLAN: 'plans_translation',
  WORKOUT: 'workouts_translation'
}